import React from 'react';
import { ContentPageDetail as ContentPageDetailModel } from '../../model/types/contentPageDetailTypes';
import SEO from '../primitives/Seo';
import ContentBlockMain from '../primitives/contentBlock/components/ContentBlockMain';
import ContentContainer from '../primitives/contentBlock/components/ContentContainer';
import ContentBlock from '../primitives/contentBlock/ContentBlock';
import PageHeader from '../primitives/pageHeader/PageHeader';
import styles from './contentPageDetail.module.scss';
import Heading, { TagOptions } from '../primitives/heading/Heading';
import ContentFulRichText from '../../contentful/components/ContentFulRichText';

type Props = {
    details: ContentPageDetailModel;
};

const ContentPageDetail = ({ details }: Props) => {
    return (
        <>
            <SEO title={`${details.title} - Platformen`} />
            <PageHeader />
            <ContentBlock additionalClassName={styles.contentBlockDetails}>
                <ContentBlockMain>
                    <ContentContainer>
                        <Heading
                            className={styles.heading1}
                            tag={TagOptions.h1}
                        >
                            {details.title}
                        </Heading>
                        <p className={styles.intro}>{details.intro.intro}</p>
                        <ContentFulRichText content={details.content} />
                    </ContentContainer>
                </ContentBlockMain>
            </ContentBlock>
        </>
    );
};

export default ContentPageDetail;
