import React from 'react';
import { graphql } from 'gatsby';
import ContentPageDetail from '../components/contentPageDetail/ContentPageDetail';
import { ContentPageQuery } from '../graphql';
import { ContentPageDetail as ContentPageDetailType } from '../model/types/contentPageDetailTypes';

type Props = {
    data: ContentPageQuery;
};

const ContentPageDataProvider = ({ data }: Props) => {
    const details = data.contentfulPage as ContentPageDetailType;

    return <ContentPageDetail details={details} />;
};

export default ContentPageDataProvider;

// The $slug parameter comes from the routing context. It is added in gatsby-node.js as part of a dynamic route
export const otherQuery = graphql`
    query ContentPage($slug: String!) {
        contentfulPage(slug: { eq: $slug }) {
            title
            content {
                json
            }
            intro {
                intro
            }
        }
    }
`;
